import React, {useState, Fragment} from 'react';
import { useForm } from "react-hook-form";
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import JoinCompleteDialog from './Header/JoinCompleteDialog'
import MuiPhoneNumber from 'material-ui-phone-number'


const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright ©'}
      &nbsp;Homes In The Holyland&nbsp;
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backButton: {
    marginTop: theme.spacing(2)
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(20)
  },
}));

export default function JoinCard(props) {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();

  const [submitOngoing, setSubmitOngoing] = useState(false);
  const [signInData, setSignInData] = useState({username: '', password: '', confirmPassword: '', firstName: '', lastName: '', phone: '', isAgent: false});
  const [agentData, setAgentData] = useState({businessName: '', businessPhone: '', positionInAgency: '', businessEmail: '', companyWebsite: ''});
  const [wantsPromos, setWantsPromos] = useState(false);
  const [complete, setComplete] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [step, setStep] = useState(1);

  function handleChange(event) {
    const { name, value } = event.target

    setSignInData(prevValues => {
      return {
        ...prevValues,
        [name]: value
      };
    });
  }

  function handlePhoneChange(value) {
    setSignInData(prevValues => {
      return {
        ...prevValues,
        phone: value
      };
    });
  }

  function handleIsAgentChange(event) {
    const { name, checked } = event.target

    setSignInData(prevValues => {
      return {
        ...prevValues,
        [name]: checked
      };
    });
  }

  function handleAgentChange(event) {
    const { name, value } = event.target

    setAgentData(prevValues => {
      return {
        ...prevValues,
        [name]: value
      };
    });
  }

  function handleWantsPromosChange(event) {
    setWantsPromos(!wantsPromos);
  }

  function handleBusinessPhoneChange(value) {
    setAgentData(prevValues => {
      return {
        ...prevValues,
        businessPhone: value
      };
    });
  }

  function handleClickShowPassword1() {
    setShowPassword1(!showPassword1);
  };
    
  function handleMouseDownPassword1(event) {
    event.preventDefault();
  };

  function handleClickShowPassword2() {
    setShowPassword2(!showPassword2);
  };
  
  function handleMouseDownPassword2(event) {
      event.preventDefault();
  };

  function handleRequestNewLink() {
    fetch('/sendVerificationEmail/' + signInData.username);
  }

  function onSubmit(event) {
    let currentStep = step;
    if (currentStep === 1 && !signInData.isAgent) {
      setStep(currentStep + 2);
    }
    else if (currentStep < 6) {
      if (step === 4) {
        setSubmitOngoing(true);
        const {username, password, firstName, lastName, phone, isAgent} = signInData;
        const {businessName, businessPhone, positionInAgency, businessEmail, companyWebsite} = agentData;
      
        fetch('/register', {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({username: username, password: password, firstName: firstName, lastName: lastName, phone: phone, wantsPromos: wantsPromos, isAgent: isAgent,
            businessName: businessName, businessPhone: businessPhone, positionInAgency: positionInAgency, businessEmail: businessEmail, companyWebsite: companyWebsite})
        })
        .then(res => {
            setSubmitOngoing(false);
            if (res.status === 200) {
              setSuccess(true);
              setComplete(true); 
            }
            else {
              setSuccess(false);
              setComplete(true); 
            }
        })
        .then(fetch('/sendVerificationEmail/' + signInData.username));
      }
      setStep(currentStep + 1);
    }
  }

  return (
    <Fragment>
    {submitOngoing ?
    <div className={classes.progress}>
      <CircularProgress />
    </div>
    :
    <Fragment>
    {!(complete && step === 6) ?
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" align='center'>
          {step === 1 || step === 2 ? 'Create Your Account' : step === 3 ? 'Welcome to Home in the Holyland. You are just a few quick steps away from creating your account.'
            : step === 4 ? 'Terms of Service' : step === 5 ? 'Check your email' : null}
        </Typography>
        <Typography style={{margin: '10px 0'}} component="h2" variant="body2" align='center'>
          {step === 1 ? 'Register here to save your favourite homes & to list homes for sale and rent.' : null}
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        {step === 1 ?
        <Fragment>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label= "First Name"
                value={signInData.firstName}
                onChange={handleChange}
                autoFocus
                inputRef={register({ maxLength: 20, pattern: /^[A-Za-z][A-Za-z'-]+([ A-Za-z][A-Za-z'-]+)*/ })}
                error={errors.firstName}
                helperText={errors.firstName && "invalid first name"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                value={signInData.lastName}
                onChange={handleChange}
                autoComplete="lname"
                inputRef={register({ maxLength: 20, pattern: /^[A-Za-z][A-Za-z'-]+([ A-Za-z][A-Za-z'-]+)*/ })}
                error={errors.lastName}
                helperText={errors.lastName && "invalid last name"}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="username"
                label="Email Address"
                name="username"
                value={signInData.username}
                onChange={handleChange}
                autoComplete="email"
                inputRef={register({ pattern: emailRegex })}
                error={errors.username}
                helperText={errors.username && "invalid email address"}
              />
            </Grid>
            <Grid item xs={12}>
              <MuiPhoneNumber
                defaultCountry={'il'}
                value={signInData.phone}
                onChange={handlePhoneChange}
                variant='outlined'
                fullWidth
                required
                label="Phone Number"
                name='phone'
                id="phone"
                autoComplete="phone"
                error={errors.phone}
                helperText={errors.username && "invalid phone number"}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword1 ? 'text' : 'password'}
                id="password"
                value={signInData.password}
                onChange={handleChange}
                autoComplete="current-password"
                inputRef={register({ minLength: 6 })}
                error={errors.password}
                helperText={errors.password && "password must be at least 6 characters"}
                InputProps={{ endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword1}
                  onMouseDown={handleMouseDownPassword1}
                  edge="end"
                >
                  {showPassword1 ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment> }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type={showPassword2 ? 'text' : 'password'}
                id="confirmPassword"
                value={signInData.confirmPassword}
                onChange={handleChange}
                autoComplete="current-password"
                inputRef={register({ validate: value => value === signInData.password})}
                error={errors.confirmPassword}
                helperText={errors.confirmPassword && "must match password"}
                InputProps={{ endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword2}
                  onMouseDown={handleMouseDownPassword2}
                  edge="end"
                >
                  {showPassword2 ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment> }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={signInData.isAgent}
                    onChange={handleIsAgentChange}
                    id="isAgent"
                    name="isAgent"
                    color="primary"
                  />
                }
              label="I am an industry professional / I plan to charge an agency/tivuch fee"
              />
              <Typography variant="body2">
                *this box must be checked if you will be charging agency fees
              </Typography>
            </Grid>
          </Grid>
          </Fragment>
          : null}
          {step === 2 ?
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="businessName"
                label="Business Name"
                name="businessName"
                value={agentData.businessName}
                onChange={handleAgentChange}
                error={errors.businessName}
                helperText={errors.businessName && "invalid business name"}
              />
            </Grid>
            <Grid item xs={12}>
              <MuiPhoneNumber
                defaultCountry={'il'}
                value={agentData.businessPhone}
                onChange={handleBusinessPhoneChange}
                variant='outlined'
                fullWidth
                required
                id="businessPhone"
                label="Business Phone"
                name="businessPhone"
                autoComplete="phone"
                error={errors.businessPhone}
                helperText={errors.businessPhone && "invalid business phone"}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="businessEmail"
                label="Business Email"
                name="businessEmail"
                value={agentData.businessEmail}
                onChange={handleAgentChange}
                autoComplete="email"
                inputRef={register({ pattern: emailRegex })}
                error={errors.businessEmail}
                helperText={errors.businessEmail && "invalid email address"}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="positionInAgency"
                label="Position In Agency"
                name="positionInAgency"
                value={agentData.positionInAgency}
                onChange={handleAgentChange}
                error={errors.positionInAgency}
                helperText={errors.positionInAgency && "invalid position in agency"}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="companyWebsite"
                label="Company Website"
                name="companyWebsite"
                value={agentData.companyWebsite}
                onChange={handleAgentChange}
                error={errors.companyWebsite}
                helperText={errors.companyWebsite && "invalid company website"}
              />
            </Grid>
          </Grid>
          : null}
          {step === 4 ?
            <Fragment>
            <TextField
              id="termsOfService"
              fullWidth
              multiline
              rows={10}
              variant="outlined"
              value={getTermsOfService()}
              disabled={true}
            />
            <Typography style={{fontWeight:'bold', marginTop:'20px'}}>There are great offers and updates coming your way. Don't be the one to miss out.</Typography>
            <FormControlLabel
              style={{marginTop: "20px"}}
              control={
                <Checkbox
                  checked={wantsPromos}
                  onChange={handleWantsPromosChange}
                  id="wantsPromos"
                  name="wantsPromos"
                  color="primary"
                />
              }
            label="Check this box to confirm you wish to receive updates, news and offers from Homes in the Holyland"
            />
            </Fragment>
          : null}
          {step === 5 ?
            <Fragment>
            <Typography component="h2" variant="h6" align='center' gutterBottom>
              Verify your email by clicking on the temporary link we sent you. Make sure to check if it was accidently marked as spam. If you don't get the email, request a new link.
            </Typography>
            <Button
              onClick={handleRequestNewLink}
              fullWidth
              variant="outlined"
              color="primary"
            >
              Request new link
            </Button>
            </Fragment>
          : null}
          {step === 2 ? 
            <Button
              color="primary"
              className={classes.backButton}
              onClick={() => setStep(1)}
            >
              Go Back
            </Button>
          : null}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {step === 1 || step === 2 ? 'Join Now' : step === 3 ? 'Next' : step === 4 ? "Yes, I've read and agree to the terms of service" : 
              step === 5 ? 'Complete Registration' : null}
          </Button>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
    :
    <JoinCompleteDialog success={success} onClose={props.onClose}/>
    }
    </Fragment>
    }
    </Fragment>
  );
}

function getTermsOfService() {
  return "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend, ex eget hendrerit accumsan, purus dolor luctus neque, ac mollis arcu nibh sed purus. Pellentesque elementum lorem vitae erat tempus aliquet. Donec cursus urna erat, et scelerisque sapien ullamcorper nec. Praesent ornare malesuada lectus blandit bibendum. Sed malesuada lorem lacus, at efficitur sapien semper sed. Integer efficitur neque et lobortis malesuada. Nam eget dignissim tortor. Praesent diam sapien, sollicitudin sed rhoncus id, pretium at arcu. Aenean in ultricies arcu. Morbi felis felis, porta sed nisl auctor, elementum malesuada purus. Nullam vehicula nibh sed mauris pulvinar tristique. Maecenas commodo imperdiet condimentum." +

  "Donec rhoncus magna sapien, eu fringilla neque pretium dignissim. Pellentesque quis lectus magna. Duis porttitor mi vel mi congue, ac commodo lectus sollicitudin. Pellentesque vehicula, ipsum bibendum fringilla volutpat, tortor ligula luctus erat, ac molestie lectus eros ut ipsum. Vestibulum arcu augue, convallis id elit id, cursus convallis ligula. Maecenas scelerisque fringilla odio eget scelerisque. Phasellus scelerisque posuere mauris, vel vestibulum urna vestibulum nec." +
  
  "Vivamus gravida fermentum tellus sit amet pulvinar. Integer vitae est nec tellus sollicitudin euismod sit amet ut dolor. Aenean aliquam diam non gravida auctor. Nam dapibus, lectus commodo pharetra mattis, sapien arcu ullamcorper dolor, quis porttitor orci diam a felis. Etiam mollis, metus condimentum molestie tempor, velit metus feugiat nulla, eu sodales erat magna at augue. Etiam congue ex magna, nec rutrum nibh vulputate vel. Praesent pretium odio at tortor porttitor, eu faucibus libero finibus. Fusce et mauris enim. Phasellus auctor, nulla sed euismod condimentum, ante arcu pellentesque dolor, non consequat nulla mauris at erat. Pellentesque tincidunt sollicitudin congue. Integer faucibus odio metus, eu placerat metus rhoncus vulputate. Nulla posuere vitae nibh in congue. Aliquam erat volutpat.";
}