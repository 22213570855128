import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.primary.main,
    margin: '0 4px',
    backgroundColor: "transparent",
    "&:hover": {
        backgroundColor: "transparent"
    }
  },
  buttonText: {
    [theme.breakpoints.down('lg')]: {
      fontSize: 'medium'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 'small'
    },
    fontWeight: "500",
    '&:hover': {
      fontWeight: "bold"
    }
  }
}));

function LogoutDialog(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    function handleClickOpen() {
      setOpen(true);
    };
  
    function handleClose() {
      setOpen(false);
      if (props.type === 'menuItem') {
        props.onMobileMenuClose();
      }
    };

    function handleLogout() {
      fetch ("/logout")
      .then(res => {
      if (res.status === 200) {
        props.onLogout();
      }});
      handleClose();
    }
  
    return (
      <div>
        {props.type === "button" ?
           props.page && props.page === 'profile' ? <Button onClick={handleClickOpen} size='medium' color='primary'><Typography style={{fontWeight: 'bold'}}>Logout</Typography></Button>
            : <Button className={classes.button} style={props.isListingLeadPage ? {color: '#ffffff'} : {}} size='large' onClick={handleClickOpen} startIcon={<ExitToAppIcon />}><Typography className={classes.buttonText}>Logout</Typography></Button>
        :
          <MenuItem  onClick={handleClickOpen}><Typography color="textPrimary">Logout</Typography></MenuItem>
        }
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="alert-dialog-title">
            Logout
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to log out?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleLogout} color="primary" autoFocus>
              Yes
            </Button>
            <Button onClick={handleClose} color="primary" autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
}

export default LogoutDialog;