import React, { Fragment } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '80vh',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor: '#1A1A1A61'
  },
  footerButton: {
    textTransform: "none",
    fontSize: '1.3rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem'
    },
    fontWeight: "bold"
  },
  buttonRoot: {
    '&:hover' : {
      backgroundColor: 'transparent'
    }
  },
  copyright: {
    fontSize: '1rem',
    color: '#ffffff',
    display: 'flex',
    justifyContent: 'center'
  },
  message: {
    fontFamily: 'Raleway',
    fontWeight: 300,
    fontSize: '1.2rem',
    color: '#ffffff',
    marginBottom: theme.spacing(3),
      width: '60%',
      [theme.breakpoints.between('xs','sm')]: {
          width: '90%'
      },
      [theme.breakpoints.between('sm','md')]: {
          width: '80%'
      },
      [theme.breakpoints.between('md','lg')]: {
          width: '70%'
      },
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  links: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(3)
  },
  link: {
    fontSize: '1.3rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem'
    },
    fontWeight: 'bold',
    padding: theme.spacing(2),
    color: '#004D91',
    textDecoration: 'none'
  },
  bottom: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonRoot: {
    '&:hover' : {
      backgroundColor: 'transparent'
    }
  },
}));

function Copyright() {
  const classes = useStyles();

  return (
    <div className={classes.bottom}>
    <Button classes={{root: classes.buttonRoot}} component={RouterLink} to="/">
      <img src="../logo.png" alt='logo-img' width='281' height='132'/>
    </Button>
    <Typography className={classes.copyright}>
      {'Copyright ©'}
      &nbsp;Homes in the Holyland&nbsp;
      {new Date().getFullYear()}
      {'.'}
    </Typography>
    </div>
  );
}

export default function StickyFooter(props) {
  const classes = useStyles();
  const onRedirect = props.onRedirect;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <footer className={classes.footer}>
        <Typography className={classes.message}>
          Homes in the Holyland is committed to easing the process of finding, renting and selling a home in Jerusalem and providing a user friendly web experience for everyone.
          We welcome feedback and accommodation requests, feel free to contact us.
        </Typography>
        <div className={classes.links}>
        <Link className={classes.link} to="/">Home</Link>
          {onRedirect ? 
            <Fragment>
              <Button classes={{root: classes.buttonRoot}} className={classes.footerButton} onClick={() => onRedirect("/propertyListings?listingType=rent")} color='primary'>Rent</Button>
              <Button classes={{root: classes.buttonRoot}} className={classes.footerButton} onClick={() => onRedirect("/propertyListings?listingType=buy")} color='primary'>Buy</Button>
            </Fragment>
          :
            <Fragment>
              <Link className={classes.link} to="/propertyListings?listingType=rent">Rent</Link>
              <Link className={classes.link} to="/propertyListings?listingType=buy">Buy</Link>
            </Fragment>
          }
          <Link className={classes.link} to="/listingLead">List Your Home</Link>
          <Link className={classes.link} to="/cookiePolicy">Cookie Policy</Link>
          <Link className={classes.link} to="/privacyPolicy">Privacy Policy</Link>
          <Link className={classes.link} to="/termsAndConditions">Terms and Conditions</Link>
          <Link className={classes.link} to="/contact">Contact Us</Link>
        </div>
        <Copyright />
      </footer>
    </div>
  );
}