import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import './App.css';

const theme = createMuiTheme();
theme.palette.primary = {main:  '#004D91', contrastText: '#fff', light: '#006ac7', dark: '#00417b'}
theme.palette.background.default = '#ffffff'
theme.shape.borderRadius = 15
theme.typography.fontFamily = "Segoe UI, Helvetica, Arial, sans-serif"
theme.typography.button.fontFamily = "Segoe UI, Helvetica, Arial, sans-serif"
theme.typography.h1.fontFamily = "Segoe UI, Helvetica, Arial, sans-serif"
theme.typography.h2.fontFamily = "Segoe UI, Helvetica, Arial, sans-serif"
theme.typography.h3.fontFamily = "Segoe UI, Helvetica, Arial, sans-serif"
theme.typography.h4.fontFamily = "Segoe UI, Helvetica, Arial, sans-serif"
theme.typography.h5.fontFamily = "Segoe UI, Helvetica, Arial, sans-serif"
theme.typography.h6.fontFamily = "Segoe UI, Helvetica, Arial, sans-serif"
theme.typography.body1.fontFamily = "Segoe UI, Helvetica, Arial, sans-serif"
theme.typography.body2.fontFamily = "Segoe UI, Helvetica, Arial, sans-serif"


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <App />
      </MuiThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

