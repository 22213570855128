import React, {Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import JoinButtonAndDialog from './JoinButtonAndDialog';
import SignInButtonAndDialog from './SignInButtonAndDialog';
import LogoutDialog from '../LogoutDialog';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: '#00000078',
    height: '80px'
  },
  appBarHomePage: {
    backgroundColor: 'transparent',
    height: '80px'
  },
  toolbar: {
    margin: '0 2%',
    [theme.breakpoints.between('xs','md')]: {
        margin: '0 0'
      },
    [theme.breakpoints.between('md','lg')]: {
        margin: '0 2%'
      }
  },
  smallLogo: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  buttonRoot: {
    '&:hover' : {
      backgroundColor: 'transparent'
    }
  },
  title: {
    display: 'block'
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    }
  },
  link: {
    textDecoration: 'none'
  },
  simpleLink: {
    color: '#ffffff',
    fontFamily: "Segoe UI",
    [theme.breakpoints.down('lg')]: {
      fontSize: 'medium'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 'small'
    },
    fontWeight: 'bold',
    '&:hover': {
      fontWeight: 'lighter'
    },
    textDecoration: 'none'
  }
}));

export default function NavBar(props) {
  const classes = useStyles();

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);


  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  function handleRedirect(url) {
    handleMobileMenuClose();
    props.onRedirect(url);
  }

  const mobileMenuId = 'primary-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={() => handleRedirect("/propertyListings?listingType=rent")}>
        <Typography className={classes.link} color="textPrimary">Rent</Typography>
      </MenuItem>
      <MenuItem onClick={() => handleRedirect("/propertyListings?listingType=buy")}>
        <Typography className={classes.link} color="textPrimary">Buy</Typography>
      </MenuItem>
      <MenuItem onClick={handleMobileMenuClose} component={RouterLink} to="/listingLead">
        <Typography className={classes.link} color="textPrimary">List</Typography>
      </MenuItem>
      <MenuItem onClick={handleMobileMenuClose} component={RouterLink} to="/findMyAgent">
        <Typography className={classes.link} color="textPrimary">Find Agent</Typography>
      </MenuItem>
      {props.isLoggedIn ? 
        <div>
          <MenuItem onClick={() => handleRedirect("/myAccount/myProfile")}>
            <Typography className={classes.link} color="textPrimary">My Account</Typography>
          </MenuItem>
          <LogoutDialog onMobileMenuClose={handleMobileMenuClose} onLogout={props.onLogout} type="menuItem"/>
        </div>
        :
        <div>
          <SignInButtonAndDialog onMobileMenuClose={handleMobileMenuClose} type="menuItem" onRedirect={props.onRedirect} onLogin={props.onLogin} />
          <JoinButtonAndDialog onMobileMenuClose={handleMobileMenuClose} type="menuItem"/>
        </div>
      }
    </Menu>
  );

  const renderSmallLogo = (
    <div style={props.isListingLeadPage ? {boxShadow: 'none'} : null} className={(props.isHomePage ? classes.smallLogo : '') + ' small-logo'}>
      <Button classes={{root: classes.buttonRoot}} component={RouterLink} to="/">
        <img src="../logo.png" alt='logo-img' width='168' height='80'/>
      </Button>
    </div>  
  )

  return (
    <Fragment>
    {props.isHomePage || props.isListingLeadPage ?
    <div className={classes.grow}>
    <div className={classes.appBarHomePage } position="static">
      <Toolbar className={classes.toolbar}>
        {renderSmallLogo}
        <div className={classes.grow} />
        <div className={classes.sectionDesktop}>
          <Button classes={{root: classes.buttonRoot}} className='simple-button' size='large' onClick={() => handleRedirect("/propertyListings?listingType=rent")}><Typography className={classes.simpleLink}>Rent</Typography></Button>
          <Button classes={{root: classes.buttonRoot}} className='simple-button' size='large' onClick={() => handleRedirect("/propertyListings?listingType=buy")}><Typography className={classes.simpleLink}>Buy</Typography></Button>
          <Button classes={{root: classes.buttonRoot}} className='simple-button' size='large' component={RouterLink} to="/listingLead"><Typography className={classes.simpleLink}>List</Typography></Button>
          <Button classes={{root: classes.buttonRoot}} className='simple-button' size='large' component={RouterLink} to="/findMyAgent"><Typography className={classes.simpleLink}>Find Agent</Typography></Button>
          {props.isLoggedIn ?
            <Fragment>
              <Button classes={{root: classes.buttonRoot}} className='simple-button account-button' size='large' component={RouterLink} to="/myAccount/myProfile"><Typography className={classes.simpleLink}>My Account</Typography></Button>
              <LogoutDialog isListingLeadPage={props.isListingLeadPage} onLogout={props.onLogout} type="button"/>
            </Fragment>
            :
            <Fragment>
              <SignInButtonAndDialog type="button" onLogin={props.onLogin} onRedirect={props.onRedirect} />
              <JoinButtonAndDialog type="button" />
            </Fragment>
          }
        </div>
        <div className={classes.sectionMobile}>
          <IconButton
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
            color="primary"
          >
            <MoreIcon />
          </IconButton>
        </div>
      </Toolbar>
    </div>
    {renderMobileMenu}
    </div>
    :
    <div className={classes.grow}>
      <AppBar className={classes.appBar} position="static">
        <Toolbar className={classes.toolbar}>
          {renderSmallLogo}
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Button classes={{root: classes.buttonRoot}} className='simple-button' size='large' onClick={() => handleRedirect("/propertyListings?listingType=rent")}><Typography className={classes.simpleLink} color="textPrimary">Rent</Typography></Button>
            <Button classes={{root: classes.buttonRoot}} className='simple-button' size='large' onClick={() => handleRedirect("/propertyListings?listingType=buy")}><Typography className={classes.simpleLink} color="textPrimary">Buy</Typography></Button>
            <Button classes={{root: classes.buttonRoot}} className='simple-button' size='large' component={RouterLink} to="/listingLead"><Typography className={classes.simpleLink} color="textPrimary">List Your Home</Typography></Button>
            <Button classes={{root: classes.buttonRoot}} className='simple-button' size='large' component={RouterLink} to="/findMyAgent"><Typography className={classes.simpleLink}>Find My Agent</Typography></Button>
            {props.isLoggedIn ?
              <Fragment>
                <Button classes={{root: classes.buttonRoot}} size='large' className='simple-button account-button' component={RouterLink} to="/myAccount/myProfile"><Typography className={classes.simpleLink} >My Account</Typography></Button>
                <LogoutDialog onLogout={props.onLogout} type="button"/>
              </Fragment>
              :
              <Fragment>
                <SignInButtonAndDialog type="button" onLogin={props.onLogin} onRedirect={props.onRedirect} />
                <JoinButtonAndDialog type="button" />
              </Fragment>
            }
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              size='medium'
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="primary"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
    }
    </Fragment>
  );
}