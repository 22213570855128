import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    cursor: 'pointer',
    boxShadow: '7px 0px 28px #00000029',
    marginBottom: theme.spacing(4),
    width: '450px',
    minHeight: '545px',
    [theme.breakpoints.down('lg')]: {
      width: '350px',
      minHeight: '350px'
    }
  },
  topLine: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: '15px'
  },
  avatar: {
    margin: 'auto',
    height: 200,
    width: 200
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 8%',
    minHeight: '300px',
    [theme.breakpoints.down('lg')]: {
      minHeight: '225px'
    }
  },
  cardActions: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: "#ffffff",
  },
  text: {
    fontFamily: 'Raleway',
    fontWeight: 400,
    fontSize: '1.3rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.25rem'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.15rem'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.1rem'
    },
  }
}));

export default function FilterCard(props) {
  const classes = useStyles();

  function redirect(linkName) {
    if (linkName === 'rent') {
      props.onRedirect("/propertyListings?listingType=rent");
    }
    else if (linkName === 'buy') {
      props.onRedirect("/propertyListings?listingType=buy");
    }
    else {
      props.onRedirect("/listingLead");
    }
  }

  return (
    <Card id='filterCard' elevation={3} className={classes.root} onClick={() => redirect(props.type)}>
      <div className={classes.topLine}></div>
      <CardMedia
        className={classes.avatar}
        image={props.type === 'rent' ? "../images/rent_icon.png" : props.type === 'buy' ? '../images/buy_icon.png' : '../images/list_icon.png'}
        title="Card Avatar"
      />
      <CardContent className={classes.cardContent}>
        {props.type === 'rent' ?
          <div>
            <Typography className={classes.text} color="textPrimary" gutterBottom>
              Choose from the following rental types:
            </Typography>
            <Typography style={{fontWeight: 'bold'}} className={classes.text} color="textPrimary">
              Long Term
            </Typography>
            <Typography style={{fontWeight: 'bold'}} className={classes.text} color="textPrimary">
              Short term
            </Typography>
            <Typography className={classes.text} color="textPrimary">
              <span style={{fontWeight: 'bold'}}>Seasonal</span> - homes let for a specific date period, on a one off basis
            </Typography>
          </div>
        :
          props.type === 'buy' ?
            <Typography className={classes.text} color="textPrimary">
              Find the perfect home in your preferred location by selecting the relevant filters.
            </Typography>
          :
            <Typography className={classes.text} color="textPrimary">
              Let your property get widely seen. Without delay! List your sale or rental today.
            </Typography>
        }
      </CardContent>
      <CardActions id='bottomLabel' className={classes.cardActions}>
        {props.type === 'rent' ?
          <span className='bottom-label-text'>
             Rent
          </span>
        :
          props.type === 'buy' ?
          <span className='bottom-label-text'>
              Buy
            </span>   
          :
            <span className='bottom-label-text'>
              List
            </span> 
        }
      </CardActions>
    </Card>
  );
}


