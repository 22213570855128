import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function JoinCompleteDialog(props) {

  return (
    <Dialog
      open={true}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {props.success ? "You're in!" : "Registration Failed"}
      </DialogTitle>
      <DialogContent>
        {props.success ? 
        <DialogContentText id="alert-dialog-description">
          Welcome to the family. Login to your account and start searching for your Home in the Holyland or head over to the 'List' page, to get your home listed. 
        </DialogContentText>
        :
        <DialogContentText id="alert-dialog-description">
          We were unable to process your request at this time. Please try again later.
        </DialogContentText>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary" autoFocus>
          {props.success ? "Get Started!" : "Ok"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default JoinCompleteDialog;