import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import NavBar from "./Header/NavBar";
import StickyFooter from './Footer/StickyFooter';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  }
}));

function ErrorPage(props) {
  const classes = useStyles();

  return (
    <main>
    <NavBar isLoggedIn={props.isLoggedIn} onLogin={props.onLogin} onRedirect={props.onRedirect} onLogout={props.onLogout}/>
    <div className={classes.root}>
    <CssBaseline />
    <Container component="main" className={classes.main} maxWidth="sm">
      <Typography variant="h2" component="h1" gutterBottom>
        Error
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        {'Page not found!'}
      </Typography>
    </Container>
  </div>
  <StickyFooter />
  </main>
  );
}

export default ErrorPage;