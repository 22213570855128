import React, { Fragment, Suspense, lazy } from 'react';
import {Route, Switch} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import HomePage from './HomePage/HomePage';
import ErrorPage from "./ErrorPage";
import MyRedirect from "./MyRedirect";

const ListingLeadPage = lazy(() => import("./ListingLeadPage/ListingLeadPage"));
const FindMyAgentPage = lazy(() => import("./FindMyAgentPage/FindMyAgentPage"));
const PropertyListingsPage = lazy(() => import("./PropertyListingsPage/PropertyListingsPage"));
const ContactPage = lazy(() => import("./ContactPage"));
const MyAccountPage = lazy(() => import("./MyAccountPage/MyAccountPage"));
const ListingFormPage = lazy(() => import('./ListingFormPage/ListingFormPage'));
const PrivacyPolicyPage = lazy(() => import('./PrivacyPolicyPage'));
const CookiePolicyPage = lazy(() => import('./CookiePolicyPage'));
const TermsAndConditionsPage = lazy(() => import('./TermsAndConditionsPage'));
const ResetPasswordPage = lazy(() => import('./ResetPasswordPage'));
const VerifyEmailPage = lazy(() => import('./VerifyEmailPage'));
const PurchaseCreditPage = lazy(() => import('./PurchaseCreditPage/PurchaseCreditPage'));
const PurchaseFeaturePage = lazy(() => import('./PurchaseFeaturePage/PurchaseFeaturePage'));
const PaymentSuccessPage = lazy(() => import('./PaymentSuccessPage'));

const useStyles = makeStyles((theme) => ({
  progress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(20)
  }
}));

function MyApp(props) {
  const classes = useStyles();

  if (props.redirect) {
    return <MyRedirect url={props.redirect} setRedirect={props.onRedirect} />
  }

  const myProps = props;

  return (
    <div>
      <Suspense fallback={<div className={classes.progress}><CircularProgress /></div>}>
      {myProps.fetchInProgress ?
        <div className={classes.progress}>
          <CircularProgress />
        </div>
      :
      <Fragment>
      <Switch>
        <Route 
          path='/'  
          exact 
          render={(props) => <HomePage isLoggedIn={myProps.loggedIn} onLogin={myProps.setIsLoggedIn} onLogout={myProps.setIsLoggedOut} onRedirect={myProps.onRedirect} {...props} />}
        />
        <Route 
          path='/listingLead' 
          render={(props) => <ListingLeadPage isLoggedIn={myProps.loggedIn} onLogin={myProps.setIsLoggedIn} onRedirect={myProps.onRedirect} onLogout={myProps.setIsLoggedOut} {...props} />}
        />
        <Route 
          path='/findMyAgent' 
          render={(props) => <FindMyAgentPage isLoggedIn={myProps.loggedIn} onLogin={myProps.setIsLoggedIn} onRedirect={myProps.onRedirect} onLogout={myProps.setIsLoggedOut} {...props} />}
        />
        <Route 
          path='/propertyListings' 
          render={(props) => <PropertyListingsPage  
              onRedirect={myProps.onRedirect} 
              isLoggedIn={myProps.loggedIn}
              onLogout={myProps.setIsLoggedOut}
              onLogin={myProps.setIsLoggedIn}
              {...props}
            />}
        />
        <Route 
          path='/listingForm'
          render={(props) => myProps.loggedIn ? <ListingFormPage {...props} isLoggedIn={myProps.loggedIn} onLogin={myProps.setIsLoggedIn} onLogout={myProps.setIsLoggedOut} onRedirect={myProps.onRedirect}/> 
                                              : <HomePage isLoggedIn={myProps.loggedIn} onLogin={myProps.setIsLoggedIn} onRedirect={myProps.onRedirect} onLogout={myProps.setIsLoggedOut} {...props} />}
        />
        <Route
          path='/myAccount/:subPage'
          render={(props) => myProps.loggedIn ? <MyAccountPage isLoggedIn={myProps.loggedIn} onLogin={myProps.setIsLoggedIn} onLogout={myProps.setIsLoggedOut} onRedirect={myProps.onRedirect} {...props} /> 
                                              : <HomePage isLoggedIn={myProps.loggedIn} onLogin={myProps.setIsLoggedIn} onRedirect={myProps.onRedirect} onLogout={myProps.setIsLoggedOut} {...props} />}
        />
        <Route
          path='/purchaseCredit'
          render={(props) => myProps.loggedIn ? <PurchaseCreditPage isLoggedIn={myProps.loggedIn} onLogin={myProps.setIsLoggedIn} onRedirect={myProps.onRedirect} onLogout={myProps.setIsLoggedOut} {...props} /> 
                                              : <HomePage isLoggedIn={myProps.loggedIn} onLogin={myProps.setIsLoggedIn} onRedirect={myProps.onRedirect} onLogout={myProps.setIsLoggedOut} {...props} />}
        />
        <Route
          path='/purchaseFeature'
          render={(props) => myProps.loggedIn ? <PurchaseFeaturePage isLoggedIn={myProps.loggedIn} onLogin={myProps.setIsLoggedIn} onRedirect={myProps.onRedirect} onLogout={myProps.setIsLoggedOut} {...props} /> 
                                              : <HomePage isLoggedIn={myProps.loggedIn} onLogin={myProps.setIsLoggedIn} onRedirect={myProps.onRedirect} onLogout={myProps.setIsLoggedOut} {...props} />}
        />
        <Route path='/contact' render={(props) => <ContactPage {...props} isLoggedIn={myProps.loggedIn} onLogin={myProps.setIsLoggedIn} onRedirect={myProps.onRedirect} onLogout={myProps.setIsLoggedOut}/>} />
        <Route path='/termsAndConditions' render={(props) => <TermsAndConditionsPage {...props} isLoggedIn={myProps.loggedIn} onLogin={myProps.setIsLoggedIn} onRedirect={myProps.onRedirect} onLogout={myProps.setIsLoggedOut}/>} />
        <Route path='/cookiePolicy' render={(props) => <CookiePolicyPage {...props} isLoggedIn={myProps.loggedIn} onLogin={myProps.setIsLoggedIn} onRedirect={myProps.onRedirect} onLogout={myProps.setIsLoggedOut}/>} />
        <Route path='/paymentSuccess' render={(props) => <PaymentSuccessPage {...props} isLoggedIn={myProps.loggedIn} onLogin={myProps.setIsLoggedIn} onRedirect={myProps.onRedirect} onLogout={myProps.setIsLoggedOut}/>} />
        <Route path='/privacyPolicy' render={(props) => <PrivacyPolicyPage {...props} isLoggedIn={myProps.loggedIn} onLogin={myProps.setIsLoggedIn} onRedirect={myProps.onRedirect} onLogout={myProps.setIsLoggedOut}/>} />
        <Route path='/emailVerification/:token' render={(props) => <VerifyEmailPage {...props} isLoggedIn={myProps.loggedIn} onLogin={myProps.setIsLoggedIn} onRedirect={myProps.onRedirect} onLogout={myProps.setIsLoggedOut}/>} />
        <Route 
          path='/resetPassword/:token'
          render={(props) => <ResetPasswordPage {...props} isLoggedIn={myProps.loggedIn} onLogin={myProps.setIsLoggedIn} onRedirect={myProps.onRedirect} onLogout={myProps.setIsLoggedOut}/>}
        />
        <Route component={ErrorPage} />
      </Switch>
      </Fragment>
      }
      </Suspense>
    </div>
  );
}

export default MyApp;