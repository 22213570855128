import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import JoinCard from "../JoinCard";
import PersonAddIcon from '@material-ui/icons/PersonAdd';

const useStyles = makeStyles((theme) => ({
  buttonText: {
    fontWeight: "200",
    [theme.breakpoints.down('lg')]: {
      fontSize: 'medium'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 'small'
    },
  }
}));

export default function JoinButtonAndDialog(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  };

  function handleClose() {
    setOpen(false);
    if (props.type === 'menuItem') {
      props.onMobileMenuClose();
    }
  };

  return (
    <div style={props.type === "button" ? {width: '127px', margin: '0 4px'} : null}>
      {props.type === "button" ?
        <Button onClick={handleClickOpen} fullWidth size='large' variant="contained" color={"primary"} startIcon={<PersonAddIcon />}><Typography className={classes.buttonText}>Join</Typography></Button>
      :
        <MenuItem  onClick={handleClickOpen}><Typography color="textPrimary">Join</Typography></MenuItem>
      }
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <JoinCard onClose={handleClose} />
      </Dialog>
    </div>
  );
}