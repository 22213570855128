import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import SignInCard from '../SignInCard';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles((theme) => ({
  buttonText: {
    fontWeight: "200",
    [theme.breakpoints.down('lg')]: {
      fontSize: 'medium'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 'small'
    },
  }
}));

export default function SignInButtonAndDialog(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  };

  function handleClose() {
    setOpen(false);
    if (props.type === 'menuItem') {
      props.onMobileMenuClose();
    }
  };

  return (
    <div style={props.type === "button" ? {minWidth: '83px', margin: '0 8px'} : null}>
      {props.type === "button" ?
        <Button className={classes.button} color={'primary'} fullWidth size='large' variant="contained" onClick={handleClickOpen} startIcon={<ExitToAppIcon />}><Typography className={classes.buttonText}>Sign In</Typography></Button>
      :
          <MenuItem onClick={handleClickOpen}><Typography color="textPrimary">Sign In</Typography></MenuItem>
      }
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <SignInCard onLogin={props.onLogin} onClose={handleClose} onRedirect={props.onRedirect} />
      </Dialog>
    </div>
  );
}