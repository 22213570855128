import React, {useEffect} from 'react';
import {Redirect} from 'react-router-dom';

function MyRedirect(props) {

  const {setRedirect} = props;
  const url = props.url;
  useEffect(() => {
    setRedirect(null);
  }, [setRedirect]);

  return (
    <Redirect push to={url} />
  );
}

export default MyRedirect;