import React, {useEffect, useState, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import FilterCard from './FilterCard';
import NavBar from "../Header/NavBar";
import { fade, makeStyles } from '@material-ui/core/styles';
import Footer from "../Footer/Footer";

const useStyles = makeStyles((theme) => ({
  heroImage: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '977px',
    backgroundSize: 'auto',
    backgroundImage: `url(${"../images/Jerusalem.png"})`,
    [theme.breakpoints.down('lg')]: {
      height: '800px',
      backgroundImage: `url(${"../images/Jerusalem.png"})`
    },
    [theme.breakpoints.down('md')]: {
      height: '651px',
      backgroundImage: `url(${"../images/Jerusalem-lg.png"})`
    },
    [theme.breakpoints.down('sm')]: { 
      height: '489px',
      backgroundImage: `url(${"../images/Jerusalem-md.png"})`
    },
    [theme.breakpoints.down('xs')]: {
      height: '489px',
      backgroundImage: `url(${"../images/Jerusalem-md.png"})`
    }
  },
  mobileOverlay: {
    position: 'absolute',
    width: '100vw',
    height: '489px',
    backgroundColor: 'rgba(0,0,0,.30)',
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'transparent'
    },
  },
  titleSection: {
    width: '600px',
    height: '80%',
    [theme.breakpoints.down('lg')]: {
      height: '80%',
      width: '540px'
    },
    [theme.breakpoints.down('md')]: {
      height: '80%',
      width: '480px'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileTitleSection: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      marginTop: '200px',
      marginReft: 'auto',
      marginRight: 'auto',
      left: 0,
      right: 0,
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
  },
  logo: {
    marginTop: '30%',
    [theme.breakpoints.down('lg')]: {
      marginTop: '20%',
      height: '207px',
      width: '440px'
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '20%',
      height: '166px',
      width: '352px'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '15%',
      height: '132px',
      width: '281px'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '10%',
      height: '105px',
      width: '224px'
    }
  },
  descriptionSection : {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
    margin: '0 7%'
  },
  filtersSection: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(15),
    margin: '0 7%',
  },
  mainTitle: {
    color: "#ffffff",
    width: '94%',
    fontFamily: 'Segoe UI Bold',
    textAlign: 'center',
    letterSpacing: '0px',
    marginTop: '5%',
    marginBottom: '3%',
    fontSize: '2.5rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '2.35rem'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.9rem'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.9rem'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.9rem'
    }
  },
  secondaryTitle: {
    fontFamily: 'Segoe UI Bold',
    letterSpacing: '0px',
    fontSize: '2.5rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '2.35rem'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.9rem'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.9rem'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.9rem'
    },
    width: '94%',
    color: "#ffffff",
    textAlign: 'center',
    marginBottom: '5%'
  },
  search: {
    display: 'flex',
    position: 'relative',
    borderRadius: '50px',
    backgroundColor: fade(theme.palette.common.white, 0.80),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.90),
    },
    width: '94%',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '70%',
    },
    height: '70px',
    [theme.breakpoints.down('lg')]: {
      height: '65px',
    },
    [theme.breakpoints.down('md')]: {
      height: '50px',
    },
  },
  searchIcon: {
    height: '59px',
    width: '65px',
    marginRight: '7px',
    [theme.breakpoints.down('lg')]: {
      height: '54px',
      width: '65px',
    },
    [theme.breakpoints.down('md')]: {
      height: '41px',
      width: '55px',
    },
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    display: 'flex',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
    height: '100%'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: theme.spacing(4),
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent!important',
    borderColor: 'transparent!important',
    outline: 'transparent!important',
    fontFamily: 'Segoe UI',
    fontSize: 'large',
    [theme.breakpoints.down('md')]: {
      fontSize: 'medium'
    },
    color: '#000000',
    '&::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: '#000000',
      opacity: 1, /* Firefox */
    },
    '&:-ms-input-placeholder': { /* Internet Explorer 10-11 */
      color: '#000000'
    },
    '&::-ms-input-placeholder': { /* Microsoft Edge */
      color: '#000000'
    }
  },
  searchForm: {
    width: '100%'
  },
  descriptionText: {
    fontFamily: 'Raleway',
    textAlign: 'center',
    fontSize: '2rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.5rem'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.3rem'
    },
  },
  filterGrid: {
    flexGrow: 1,
  }
}));

function HomePage(props) {
  const classes = useStyles();

  const [neighborhoodList, setNeighborhoodList] = useState([]);
  const [searchInputTerm, setSearchInputTerm] = useState(null);

  function handleSearchInputChange(event, value) {
    setSearchInputTerm(value);
  }

  function handleSearchInputSubmit(event) {
    event.preventDefault();
    if (searchInputTerm) {
      const neighborhoodParam = "neighborhoods=" + searchInputTerm;
      props.onRedirect("/propertyListings/?listingType=rent&" + neighborhoodParam);
    }
  };

  useEffect(() => {
    let isActive = true;

    window.scrollTo(0, 0);
    fetch('/listingSpecList/neighborhood')
    .then((res) => res.json())
    .then((data) => {
      if (isActive) {
        setNeighborhoodList(data.specNameList);
      }
    });

    return () => {
      isActive = false;
    };
  },[]);

  const renderMobileTitleSection = (
    <div className={classes.mobileTitleSection}>
    <Typography style={{color: '#ffffff', fontSize: '2.2rem', fontWeight: 'bold', marginBottom: '10px'}}>
      A Home you will love
    </Typography>
    <Typography style={{color: '#ffffff', fontSize: '1.7rem', fontWeight: 'normal', marginBottom: '20px'}}>
      in the place you call Home
    </Typography>
    <div className={classes.search}>
      <form className={classes.searchForm} onSubmit={handleSearchInputSubmit}>
        <Autocomplete
          classes={{
            root: classes.inputRoot,
            inputRoot: classes.inputInputRoot,
            input: classes.inputInput,
          }}
          disableClearable={true}
          options={neighborhoodList}
          getOptionLabel={(option) => option}
          value={searchInputTerm}
          onChange={handleSearchInputChange}
          ListboxProps={{
            style:{
              maxHeight: '200px'
            }
          }}
          renderInput={(params) => (    
            <div style={{ width: '100%', height: '100%' }} ref={params.InputProps.ref}>
              <input type="text" {...params.inputProps} placeholder='Enter a neighborhood in Jerusalem...'/>
            </div>
          )}
        />
      </form>
      <div className={classes.searchIcon} onClick={handleSearchInputSubmit} aria-label="Search for Neighborhood">
        <SearchIcon className='search-icon' style={{color: '#ffffff', fontSize: '1.7rem'}}/>
      </div>
    </div>
  </div>
  )

  return (
    <Fragment>
    <main>
      <div className={classes.heroImage}>
      <div className={classes.mobileOverlay}></div>
      <div className={classes.titleSection + ' ' + 'title-section'}>
        <img className={classes.logo} src="../logo.png" alt='logo-img' width='550' height='259'/>
        <Typography className={classes.mainTitle} variant="h4">
          {"A "}<span style={{color: '#004D91'}}>Home</span>{" you will love"}
        </Typography>
        <Typography className={classes.secondaryTitle}>
          {"in the place you call "}<span style={{color: '#004D91'}}>Home</span>
        </Typography>
        <div className={classes.search}>
          <form className={classes.searchForm} onSubmit={handleSearchInputSubmit}>
            <Autocomplete
              classes={{
                root: classes.inputRoot,
                inputRoot: classes.inputInputRoot,
                input: classes.inputInput,
              }}
              disableClearable={true}
              options={neighborhoodList}
              getOptionLabel={(option) => option}
              value={searchInputTerm}
              onChange={handleSearchInputChange}
              ListboxProps={{
                style:{
                  maxHeight: '200px'
                }
              }}
              renderInput={(params) => (    
                <div style={{ width: '100%', height: '100%' }} ref={params.InputProps.ref}>
                  <input type="text" {...params.inputProps} placeholder='Enter a neighborhood in Jerusalem...'/>
                </div>
              )}
            />
          </form>
          <div className={classes.searchIcon} onClick={handleSearchInputSubmit} aria-label="Search for Neighborhood">
            <SearchIcon className='search-icon' style={{color: '#ffffff', fontSize: '2.2rem'}}/>
          </div>
        </div>
      </div>
      <NavBar isHomePage={true} isLoggedIn={props.isLoggedIn} onLogin={props.onLogin} onRedirect={props.onRedirect} onLogout={props.onLogout}/>
      {renderMobileTitleSection}
      </div>
      <div className={classes.descriptionSection}>
        <Typography className={classes.descriptionText} variant="h5" component="h2">
          Your home finding experience in Jerusalem has been redesigned, with you in mind.  We are here to ease your experience by connecting you to a centralised marketplace of sales and rentals, 
          from agents and homeowners across Jerusalem. You are bound to find something you will love. Go for it. And make your dream a living reality.
        </Typography>
      </div>
      <div className={classes.filtersSection}>
        <div className={classes.filterGrid}>
          <Grid container justify="center">
            <Grid item sm={12} md={6} lg={4} align='center'>
              <FilterCard onRedirect={props.onRedirect} type='rent' />
            </Grid>
            <Grid item sm={12} md={6} lg={4} align='center'>
              <FilterCard onRedirect={props.onRedirect} type='buy' />
            </Grid>
            <Grid item sm={12} md={12} lg={4} align='center'>
              <FilterCard onRedirect={props.onRedirect} type='list' />
            </Grid>
          </Grid>
        </div>
      </div>
    </main>
    <Footer />
    </Fragment>
  );
}

export default HomePage;