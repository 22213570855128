import React, {useState, Fragment} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SignInFailedDialog from './SignInFailedDialog';
import JoinCard from './JoinCard';

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright ©'}
        &nbsp;Homes In The Holyland&nbsp;
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(20)
  },
  copyrightBox: {
    marginBottom: theme.spacing(3)
  }
}));

export default function SignInCard(props) {
  const classes = useStyles();

  const [submitOngoing, setSubmitOngoing] = useState(false);
  const [loginData, setLoginData] = useState({username: '', password: ''});
  const [failed, setFailed] = useState(false);
  const [openRegister, setOpenRegister]= useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  const [email, setEmail] = useState('');

  function handleChange(event) {
    const { name, value } = event.target;

    setLoginData(prevValues => {
      return {
        ...prevValues,
        [name]: value
      };
    });
  }

  function onClick(event) {
    event.stopPropagation();
  }

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  };
  
  function handleMouseDownPassword(event) {
      event.preventDefault();
  };

  function handleForgotPassword() {
    setOpenForgotPassword(true);
  }

  function handleSendForgotPassEmail() {
    setOpenForgotPassword(false);
    fetch('/forgotPassword/' + email)
    .then((res) => {
      if (res.status === 200) {
        props.onRedirect('/');
      }
      else {
        props.onRedirect('/');
      }
    });
  }

  function onFailedClose() {
    setFailed(false);
  }

  function onSubmit(event) {
    event.preventDefault();
    setSubmitOngoing(true);
    const {username, password} = loginData;
    
    fetch('/login', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({username, password})
    })
    .then((res) => {
      if (res.status === 200) {
        props.onLogin(props.redirect);
      }
      else {
        throw new Error('error');
      }
    })
    .catch(error => {
      setSubmitOngoing(false);
      setFailed(true);
    });
  }

  return (
    <Fragment>
    {submitOngoing ?
    <div className={classes.progress}>
      <CircularProgress />
    </div>
    :
    <Fragment>
    <Container component="main" maxWidth="xs" onClick={onClick}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        {props.fromFavorite ? 
          <Typography component="h1" variant="h5">
            <Box textAlign="center" m={1}>
              Sign in to your account to add to your favorites family
            </Box>
          </Typography>
        :
          <Typography component="h1" variant="h5">
             Sign In
          </Typography>
        }
        <form className={classes.form} onSubmit={onSubmit} >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="username"
            value={loginData.username}
            onChange={handleChange}
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            id="password"
            value={loginData.password}
            onChange={handleChange}
            autoComplete="current-password"
            InputProps={{ endAdornment: <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment> }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs align='center'>
              <Button color="primary" size="small" onClick={handleForgotPassword}>
                Forgot password?
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs align='center'>
              <Button color="primary" size="small" onClick={() => setOpenRegister(true)}>
                Create new Account
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box className={classes.copyrightBox} mt={8}>
        <Copyright />
      </Box>
    </Container>
    <Dialog
      open={openForgotPassword}
      onClose={() => setOpenForgotPassword(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Forgot Password
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Please enter your email address and press OK. You will recieve an email shortly containing a temporary link to change your password.
        </DialogContentText>
        <TextField
          variant="outlined"
          fullWidth
          id="email"
          label="Email Address"
          value={email}
          onChange={(event) => {setEmail(event.target.value)}}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSendForgotPassEmail} color="primary" autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
    <Dialog
      open={failed}
      onClose={onFailedClose}
    >
      <SignInFailedDialog onClose={onFailedClose}/>
    </Dialog>
    <Dialog open={openRegister} onClose={() => setOpenRegister(false)}>
        <JoinCard onClose={() => setOpenRegister(false)} />
    </Dialog>
    </Fragment>
    }
    </Fragment>
  );
}