import React, {useState, useEffect} from 'react';
import MyApp from './MyApp';

function App() {

const [fetchInProgress1, setFetchInProgress1] = useState(true);
const [fetchInProgress2, setFetchInProgress2] = useState(true);
const [loggedIn, setLoggedIn] = useState(false);
const [redirect, setRedirect] = useState(null);  

const setIsLoggedIn = (redirect) => {
  setLoggedIn(true); 
  if (redirect) {
    setRedirect(redirect)
  }
  else {
    setRedirect("/");
  }
}

const setIsLoggedOut = () => {
  setLoggedIn(false); 
  setRedirect("/");
}

function onRedirect(url) {
  setRedirect(url);
}

useEffect(() => {
  let isActive = true;

  fetch('/login', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'}
  })
  .then((res) => {
    if (res.status === 200) {
      setLoggedIn(true);
    }
    else {
      setLoggedIn(false);
    }
    setFetchInProgress1(false);
  })

  fetch('/configs')
  .then((res) => res.json())
  .then((data) => {
    if (isActive) {
      localStorage.setItem('activateListingsPage', data.activateListingsPage);
      localStorage.setItem('activateRequirePayment', data.activateRequirePayment);
      localStorage.setItem('showTestimonials', data.showTestimonials);
    }
    setFetchInProgress2(false);
  });

  return () => {
    isActive = false;
  };
},[]);

return (
  <MyApp 
    fetchInProgress={fetchInProgress1 || fetchInProgress2} 
    loggedIn={loggedIn} 
    redirect={redirect} 
    onRedirect={onRedirect}
    setIsLoggedIn={setIsLoggedIn} 
    setIsLoggedOut={setIsLoggedOut}
  />
);
}

export default App;
